import {
  useRef,
  useState,
  useEffect,
} from 'react';
import Pusher from 'pusher-js';
import { getPusherConfig } from 'config/index';
import store from 'store';

export const getWSClient = () => {
  const { key, endpoint, ...config } = getPusherConfig();
  const options = {
    ...config,
    channelAuthorization: {
      endpoint,
      headersProvider: () => {
        const { credentials } = store.getState().auth;
        if (!credentials) return {};
        const userId = store.getState().SESSION?.payload?.user?.id;
        const authHeaders = {
          uid: credentials.uid,
          expiry: credentials.expiry,
          client: credentials.client,
          'token-type': credentials['token-type'],
          'access-token': credentials['access-token'],
          'X-User-Id': userId,
        };
        return authHeaders;
      },
    },
  };
  const client = new Pusher(key, options);
  return client;
};

export const useClient = () => {
  const pusherClient = useRef(null);
  const [connectionState, setConnectionState] = useState(null);

  useEffect(() => {
    pusherClient.current = getWSClient();
    return () => {
      pusherClient.current.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!pusherClient.current) return () => {};
    const callback = (states) => { setConnectionState(states); };
    pusherClient.current.connection.bind(
      'state_change',
      callback,
    );
    return () => {
      pusherClient.current.connection.unbind('state_change', callback);
    };
  }, []);

  return {
    connectionState,
    client: pusherClient.current,
  };
};
