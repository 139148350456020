import { useEffect } from 'react';
import { useDisclosure } from 'ui';
import { SESSION_INFO_REASONS } from 'config/constants';
import { useReduxSubscription } from 'utils/redux-query';

const REASONS = Object.values(SESSION_INFO_REASONS)
  .map(({ name }) => name);

export const useModalDisclosure = (props) => {
  const {
    onToggle,
    onOpen,
    isOpen,
    onClose,
  } = useDisclosure(props);
  const [sessionStatus] = useReduxSubscription('SESSION_STATUS');
  const sessionEndsReason = sessionStatus?.reason;
  const hasSessionEnded = REASONS.includes(sessionEndsReason);

  useEffect(() => {
    if (!hasSessionEnded) return;
    onClose();
  }, [hasSessionEnded]);

  return {
    onToggle,
    onOpen,
    isOpen,
    onClose,
  };
};
