const equalArrays = (arr1 = [], arr2 = []) => {
  if (arr1.length !== arr2.length) return false;

  return Boolean(!arr1.find((element, index) => element !== arr2[index]));
};

const simpleMemoize = (fn) => {
  let lastArg;
  let lastResult;
  return (...arg) => {
    if (!equalArrays(arg, lastArg)) {
      lastArg = arg;
      lastResult = fn(...arg);
    }
    return lastResult;
  };
};

export default simpleMemoize;
