import { usePrivateInvestorChannel } from 'hooks/usePrivateInvestorChannel';
import { useBindSessionInformation } from './useBindSessionInformation';
import { useBindAccountStatementBrief } from './useBindAccountStatementBrief';
import { useBindMatiVerification } from './useBindMatiVerification';
import { useBindCreateOrderCompleted } from './useBindCreateOrderCompleted';
import { useBindCreateOrderFailed } from './useBindCreateOrderFailed';
import { useBindPortfolioBrief } from './useBindPortfolioBrief';
import { useBindUpdatingPortfolio } from './useBindUpdatingPortfolio';
import { useUpdatingTransactions } from './useBindUpdatingTransactions';
import { useBindAccountStatementDocCompleted } from './useBindAccountStatementDocCompleted';
import { useBindUpdatingDiversification } from './useBindDiversificationUpdating';
import { useBindUpdatingSession } from './useBindUpdatingSession';
import { useBindBankAccountValidated } from './useBindBankAccountValidated';

export const useBindEvents = () => {
  const {
    isLoading,
    bindEvent,
    isSubscribed,
  } = usePrivateInvestorChannel();
  useBindPortfolioBrief({ bindEvent });
  useBindUpdatingSession({ bindEvent });
  useBindMatiVerification({ bindEvent });
  useUpdatingTransactions({ bindEvent });
  useBindCreateOrderFailed({ bindEvent });
  useBindUpdatingPortfolio({ bindEvent });
  useBindSessionInformation({ bindEvent });
  useBindBankAccountValidated({ bindEvent });
  useBindCreateOrderCompleted({ bindEvent });
  useBindAccountStatementBrief({ bindEvent });
  useBindUpdatingDiversification({ bindEvent });
  useBindAccountStatementDocCompleted({ bindEvent });

  return {
    isLoading,
    isSubscribed,
  };
};
