import React from 'react';
import { jsx } from 'i18n';
import { isEmail, isForbiddenDomain } from 'utils/email';
import { ts } from './utils';

const wrapError = (message) => ({ message, type: 'danger' });

export const resolver = (values, context) => {
  const { email, otpCode } = values;
  const { currentEmail } = context;

  const errors = {};

  if (!email) errors.email = wrapError(ts('errors.required'));
  if (email && !isEmail(email)) {
    errors.email = wrapError(jsx('errors.invalidEmail', {
      t: ts,
      chars: (
        <span style={{ fontWeight: 900, fontSize: '1.5rem', fontStyle: 'italic' }}>
          {ts('errors.validEmailChars')}
        </span>
      ),
    }));
  }
  if (!otpCode || otpCode?.length < 6) errors.otpCode = wrapError(ts('errors.otpRequired'));
  if (isForbiddenDomain(email)) errors.email = wrapError(ts('errors.forbiddenDomain'));
  if (email.toLowerCase() === currentEmail.toLowerCase()) errors.email = wrapError(ts('errors.differentEmail'));
  return {
    errors,
    values,
  };
};
