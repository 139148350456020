import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import { Flex, Text } from 'ui';
import HeaderWithImage2FA from './HeaderWithImage2FA';
import OptionsList from './OptionsList';

const ts = (id, options) => t(id, { scope: 'Dashboard.activate2FAWizard.steps.initialState.body', ...options });

const FirstStep = (props) => {
  const { setCurrentModal, phone } = props;
  return (
    <Flex direction="column" bgColor="white">
      <HeaderWithImage2FA p="3rem" />
      <Flex direction="column" p="1rem">
        <Text mb="2rem">
          {ts('instructions.0')}
          <Text as="b">{ts('instructions.1')}</Text>
          {ts('instructions.2')}
        </Text>
        <OptionsList setCurrentModal={setCurrentModal} phone={phone} />
      </Flex>
    </Flex>
  );
};

FirstStep.propTypes = {
  setCurrentModal: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
};

export default FirstStep;
