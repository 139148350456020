import React, { createContext, useContext } from 'react';
import { useController } from './hooks';

export const activityMonitorContext = createContext();

export const ActivityMonitorProvider = ({ children }) => {
  const value = useController();

  return (
    <activityMonitorContext.Provider value={value}>
      {children}
    </activityMonitorContext.Provider>
  );
};

export const useActivityMonitor = () => useContext(activityMonitorContext);
