import React from 'react';
import { TextField } from 'components/FormFields';
import { Button, Divider, Flex } from 'ui';
import { AuthorizeWithOTPField } from 'components/AuthorizeWithOTPField';
import { useController } from './hooks/useController';
import { ts } from './utils';

export const EmailForm = ({
  onCancel,
  onSuccess,
  hideEmailForm,
}) => {
  const {
    control,
    onSubmit,
    otpProps,
    submitProps,
  } = useController({
    formProps: {
      onSuccess,
      hideEmailForm,
    },
  });

  return (
    <Flex
      as="form"
      flexDirection="column"
      padding="5"
      onSubmit={onSubmit}
      rowGap="5"
      width="320px"
      justifyContent="center"
      alignItems="center"
    >
      <TextField
        control={control}
        color="schema.gray.700"
        labelText={ts('emailLabel')}
        name="email"
        data-testid="email"
        textTransform="lowercase"
      />
      <Divider borderColor="gray.300" />
      <AuthorizeWithOTPField
        size="sm"
        name="otpCode"
        type="alphanumeric"
        data-testid="otpCode"
        placeSelf="center"
        {...otpProps}
      />
      <Flex
        gap="5"
        justifyContent="space-evenly"
        width="100%"
      >
        <Button
          variant="ghost"
          onClick={onCancel}
        >
          {ts('actions.cancel')}
        </Button>
        <Button
          type="submit"
          {...submitProps}
        >
          {ts('actions.submit')}
        </Button>
      </Flex>
    </Flex>
  );
};
