import { tscope } from 'i18n';
import React from 'react';
import { Card, Heading, Text } from 'ui';

const ts = tscope('settings.AccountStatements.EmptyState');

export const EmptyState = () => (
  <Card padding="10" gap="5">
    <Heading textAlign="center" fontSize="2xl">{ts('title')}</Heading>
    <Text textAlign="center">{ts('body')}</Text>
  </Card>
);
