import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logOutUser } from 'actions/auth/fetcher';
import { useReduxSubscription } from 'utils/redux-query';
import { useLogin } from 'hooks/auth';
import { SESSION_INFO_REASONS } from 'config/constants';

const REASONS = Object.values(SESSION_INFO_REASONS)
  .map(({ name }) => name);

const getSessionMessage = (reason) => Object.values(SESSION_INFO_REASONS)
  .find(({ name }) => name === reason)?.message || '';

export const useController = () => {
  const dispatch = useDispatch();
  const { login, isLoading, error } = useLogin();
  const isError = !!error;
  const [session] = useReduxSubscription('SESSION');
  const email = session?.user?.email;
  const [password, setPassword] = useState('');
  const [sessionStatus] = useReduxSubscription('SESSION_STATUS');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const hasSessionExpired = REASONS.includes(sessionStatus?.reason);
    setIsOpen(hasSessionExpired);
  }, [sessionStatus?.reason]);

  const onEndSession = async () => {
    await dispatch(logOutUser());
    setIsOpen(false);
  };

  const onStartSession = async (event) => {
    event.preventDefault();
    await login({
      email,
      password,
    });
    setIsOpen(false);
  };

  const onChangePasswordInput = (event) => {
    const { value } = event.target;
    setPassword(value);
  };

  const content = getSessionMessage(sessionStatus?.reason);

  return {
    isOpen,
    isError,
    content,
    isLoading,
    onEndSession,
    onStartSession,
    onChangePasswordInput,
  };
};
