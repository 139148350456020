import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { t } from 'i18n';
import { useReduxQuery } from 'utils/redux-query';
import { showToastError, showToastSuccess } from 'utils/toastHandler';
import { INVALID_OTP_ERROR, INVALID_RECORD_ERROR } from 'config/constants';
import { useUpdateEmailMutation } from '../graphql/useUpdateEmailMutation';
import { resolver, ts } from '../utils';

const onInvalidOTPError = () => {
  showToastError(t('common.errors.invalidOtp'));
};
const onInvalidEmailError = () => {
  showToastError(
    ts('errors.failedUpdateEmail'),
  );
};

const defaultValues = {
  email: '',
  otpCode: '',
};

export const useEmailForm = ({
  onSuccess,
  hideEmailForm,
}) => {
  const [session] = useReduxQuery('SESSION');
  const currentEmail = session?.user?.email ?? '';

  const [updateEmail, { loading: isSubmitting }] = useUpdateEmailMutation({
    onErrorCode: {
      [INVALID_OTP_ERROR]: onInvalidOTPError,
      [INVALID_RECORD_ERROR]: onInvalidEmailError,
    },
  });

  const context = {
    currentEmail,
  };

  const {
    reset,
    control,
    clearErrors,
    handleSubmit,
  } = useForm({
    context,
    resolver,
    defaultValues,
  });

  const handleOnCompleted = () => {
    showToastSuccess(ts('success'));
    onSuccess();
  };

  const submitHandler = (values) => {
    const { email, otpCode } = values;
    updateEmail(
      {
        variables: { input: { otpCode, email } },
        onCompleted: handleOnCompleted,
      },
    );
  };

  const onSubmit = handleSubmit(submitHandler);

  useEffect(() => {
    if (hideEmailForm) reset(defaultValues);
  }, [hideEmailForm]);

  return {
    control,
    onSubmit,
    clearErrors,
    isSubmitting,
  };
};
