import { OTP_CODE_LENGHT_DEFAULT } from 'config/constants';
import { t, tscope } from 'i18n';

const ts = tscope('Forms.fields.errors');
const tFields = (key, options) => t(key, { scope: 'settings.AccountStatements.downloadStatementForm.fields', ...options });
const buildError = ({ message }) => ({
  message,
  type: 'danger',
});

export const resolver = (values) => {
  const {
    otp,
    month,
  } = values;
  const errors = {};
  if (!month) {
    errors.month = buildError({
      message: tFields('month.errors.required'),
    });
  }
  if (!otp) {
    errors.otp = buildError({
      message: tFields('password.errors.required'),
    });
  }
  if (otp && otp.length !== OTP_CODE_LENGHT_DEFAULT) {
    errors.otp = buildError({
      message: ts('otpInvalidLength', { length: OTP_CODE_LENGHT_DEFAULT }),
    });
  }

  return {
    values,
    errors,
  };
};
