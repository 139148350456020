import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { t } from 'i18n-js';
import { useReduxMutator } from 'utils/redux-query';
import Button from 'components/Button/Button';
import { Flex } from 'styles';
import TokenField from 'components/Form/CustomFields/TokenField/TokenField';
import defaultValidator from 'components/OTPForm/config/validator';
import composeValidators from 'components/OTPForm/config/composeValidators';
import DotsLoader from 'components/DotsLoader';
import { Text } from 'components/Typography';
import { useGenerateOTP } from 'hooks';
import { showToastError, showToastSuccess } from 'utils/toastHandler';

const ts = (id, options) => t(id, { scope: 'Dashboard.activate2FAWizard.steps.2FAInstructions', ...options });
const alerts = (id, options) => t(id, { scope: 'Dashboard.activate2FAWizard.steps.toasts', ...options });

const ConfirmChangeOTPForm = (props) => {
  const { enableResendSMS, onConfirmChange, customValidator = () => ({}) } = props;
  const onConfirmChangeSuccess = () => {
    showToastSuccess(alerts('success'));
    onConfirmChange();
  };
  const onConfirmChangeError = () => {
    showToastError(alerts('invalidCode'));
  };
  const [
    confirmChange,
    { status: confirmChangeStatus },
  ] = useReduxMutator(
    'CONFIRM_CHANGE_SECOND_FACTOR',
    {
      onSuccess: onConfirmChangeSuccess,
      onError: onConfirmChangeError,
    },
  );
  const { sendToSMS } = useGenerateOTP();

  const handleConfirmChange = async (values) => {
    const { otp } = values;
    const confirmChangeParams = {
      otpCode: otp,
    };
    confirmChange(confirmChangeParams);
  };

  const resendHandler = () => {
    sendToSMS();
  };

  const validator = useCallback(composeValidators(defaultValidator, customValidator), [
    customValidator,
  ]);

  return (
    <Form onSubmit={handleConfirmChange} validate={validator}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Flex direction="column" rowGap="1rem" width="256px" my="1rem">
            <TokenField data-testid="otp-field-confirm" />
            <Button size="medium" type="submit" disabled={confirmChangeStatus.pending}>
              {confirmChangeStatus.pending ? (
                <DotsLoader color="schema.gray.300" />
              ) : (
                ts('buttons.confirmButton')
              )}
            </Button>
            {enableResendSMS ? (
              <Text size="small" color="schema.gray.500">
                {ts('troubleshooting.reSend.0')}
                <Button
                  fontSize="1.2rem"
                  lineHeight="1.5rem"
                  $type="link"
                  fontWeight="normal"
                  type="button"
                  ml="0.5rem"
                  onClick={resendHandler}
                >
                  {ts('troubleshooting.reSend.1')}
                </Button>
              </Text>
            ) : null}
          </Flex>
        </form>
      )}
    </Form>
  );
};

ConfirmChangeOTPForm.propTypes = {
  enableResendSMS: PropTypes.bool,
  onConfirmChange: PropTypes.func,
};

ConfirmChangeOTPForm.defaultProps = {
  enableResendSMS: false,
  onConfirmChange: () => { },
};

export default ConfirmChangeOTPForm;
