import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReduxQuery } from 'utils/redux-query';
import { logOutUser } from 'actions/auth/fetcher';
import {
  USER_STATUSES,
} from 'config/constants';
import { useResetAIConfig } from 'hooks/dashboard';
import { useAccountStatements } from './useAccountStatements';
import { usePortfolioInvestments } from './usePortfolioInvestments';
import { useBindEvents } from './useBindEvents';
import { useCachingTransactionHandler } from './useCachingTransactionHandler';
import { useCachingPortfolioHandler } from './useCachingPortfolioHandler';
import useRegulationRedirect from './useRedirect';

export const useController = () => {
  const dispatch = useDispatch();
  const [session, { status: sessionStatus }] = useReduxQuery('SESSION');
  const credentials = useSelector(({ auth }) => auth.credentials);
  const isUserBlocked = session?.user?.status === USER_STATUSES.BLOCKED;
  const { isLoading: isSubscriptionLoading } = useBindEvents();
  const isAppReady = sessionStatus.completed && isSubscriptionLoading === false;
  const isMissingStatus = !session?.user?.status;

  useEffect(() => {
    if (!credentials?.uid || sessionStatus.failed) {
      dispatch(logOutUser());
    }
  }, [credentials?.uid, sessionStatus.failed]);

  useResetAIConfig();
  useRegulationRedirect();
  useAccountStatements();
  usePortfolioInvestments();
  useCachingPortfolioHandler();
  useCachingTransactionHandler();

  return {
    isAppReady,
    isUserBlocked,
    isMissingStatus,
  };
};
