import debounce from 'lodash.debounce';
import { useMemo, useEffect } from 'react';

const DEBOUNCE_WAIT = 30000;
const DEBOUNCE_OPTIONS = { leading: true, trailing: false, maxWait: DEBOUNCE_WAIT };

export const useActivityListener = ({ onExtendSession }) => {
  const onActivity = useMemo(
    () => debounce(onExtendSession, DEBOUNCE_WAIT, DEBOUNCE_OPTIONS),
    [onExtendSession],
  );

  useEffect(() => {
    document.addEventListener('keypress', onActivity);
    return () => document.removeEventListener('keypress', onActivity);
  }, [onActivity]);

  useEffect(() => {
    document.addEventListener('click', onActivity);
    return () => document.removeEventListener('click', onActivity);
  }, [onActivity]);

  useEffect(() => {
    document.addEventListener('wheel', onActivity);
    return () => document.removeEventListener('wheel', onActivity);
  }, [onActivity]);

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) onActivity();
    });
    return () => document.removeEventListener('visibilitychange', onActivity);
  }, [onActivity]);
};
