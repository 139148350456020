import React from 'react';
import PropTypes from 'prop-types';
import {
  FIRST_STEP, MI_TOKEN_YTP, EXTERNAL_APP, SMS,
} from './config/Constants';
import FirstStep from './FirstStep/FirstStep';
import MiTokenYtp from './SecondStep/MiTokenYtp';
import ExternalApp from './SecondStep/ExternalApp';
import SelectSMS from './SecondStep/SelectSMS';

export const ModalSwitch = (props) => {
  const {
    currentModal, setCurrentModal, onClose, email, phone, unobscuredPhone,
  } = props;

  switch (currentModal) {
    case FIRST_STEP: {
      return <FirstStep setCurrentModal={setCurrentModal} phone={phone} />;
    }
    case MI_TOKEN_YTP: {
      return <MiTokenYtp setCurrentModal={setCurrentModal} />;
    }
    case EXTERNAL_APP: {
      return <ExternalApp setCurrentModal={setCurrentModal} email={email} />;
    }
    case SMS: {
      return (
        <SelectSMS
          onClose={onClose}
          setCurrentModal={setCurrentModal}
          email={email}
          phone={phone}
          unobscuredPhone={unobscuredPhone}
        />
      );
    }
    default:
      return null;
  }
};

ModalSwitch.propTypes = {
  currentModal: PropTypes.string.isRequired,
  setCurrentModal: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  unobscuredPhone: PropTypes.string.isRequired,
};
