import * as API from 'apis/investor/profile/email';
import { createAsyncAction, createActions } from 'utils/redux-query';

const { patch: patchSession } = createActions('SESSION');

export const updateEmail = createAsyncAction(
  'UPDATE_PROFILE_EMAIL',
  ({ email, otpCode }) => async (dispatch, getStore) => {
    const result = await API.update({ email, otpCode });

    const { payload: session } = getStore().SESSION;

    dispatch(
      patchSession(
        {
          ...session,
          user: {
            ...session.user,
            email,
          },
        },
        {},
      ),
    );

    return result;
  },
);
