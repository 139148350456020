import { useMemo, useState } from 'react';

export const useController = () => {
  const [isStopped, setIsStopped] = useState();

  const stop = () => {
    setIsStopped(true);
  };

  const start = () => {
    setIsStopped(false);
  };

  return useMemo(() => ({
    stop,
    start,
    isStopped,
  }), [isStopped]);
};
