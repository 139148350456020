const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const USERNAME_ALLOWED_CHARS_REGEX = /^[a-zA-Z0-9._+-]+$/;
const validateUsername = (email) => {
  const [username] = email.split('@');
  return USERNAME_ALLOWED_CHARS_REGEX.test(username) === true;
};

export const isEmail = (email = '') => Boolean(email.match(emailRegex)) && validateUsername(email);

const FORBIDDEN_EMAIL_DOMAINS = [
  '@yotepresto.com',
];

export const isForbiddenDomain = (email = '') => FORBIDDEN_EMAIL_DOMAINS.some((domain) => email.includes(domain));
