import { useForm } from 'react-hook-form';
import { showToastInfo } from 'utils/toastHandler';
import { downloadFile } from 'utils/window/downloadFile';
import { tAlerts, tView } from '../constants';
import { useAccountStatement } from './graphql';
import { resolver } from '../resolver';

export const useRequestAccountStatementForm = () => {
  const {
    reset,
    watch,
    control,
    setValue,
    setError,
    handleSubmit,
  } = useForm({
    resolver,
    delayError: 500,
  });
  const monthValue = watch('month');

  const onAccountStatementComplete = ({
    accountStatement,
  }) => {
    const fileConfig = {
      link: accountStatement.url,
      fileName: tView('downloadPDF'),
    };
    downloadFile(fileConfig);
    showToastInfo(tAlerts('info.pdfDownloaded'));
    reset();
  };

  const [
    getAccountStatement,
    { loading: isLoading },
  ] = useAccountStatement({
    onErrorCode: {
      INVALID_OTP_ERROR: () => {
        setError('otp', { message: tAlerts('errors.invalidOTP') });
      },
    },
    onCompleted: onAccountStatementComplete,
  });

  const onSubmit = handleSubmit(({
    otp,
    month,
  }) => {
    getAccountStatement({
      variables: { accountStatementId: month },
      context: { otpCode: otp },
    });
  });

  return {
    control,
    onSubmit,
    setValue,
    isLoading,
    month: monthValue,
  };
};
