import { gql } from '@apollo/client';
import { useMutation } from 'hooks/apollo';

export const UPDATE_EMAIL = gql`
mutation UPDATE_EMAIL($input: UpdateClientEmailMutationInput!) {
  updateClientEmail(input: $input) {
    user {
      id
      email
    }
  }
}
`;

export const useUpdateEmailMutation = (options) => useMutation(UPDATE_EMAIL, options);
