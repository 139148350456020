import { setExpiredByInactivity } from 'actions/auth/session';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useReduxSubscription } from 'utils/redux-query';
import { useActivityMonitor } from 'providers/ActivityMonitor';
import isNil from 'utils/isNil';
import { getSessionConfig } from 'config/constants';
import { useTimer } from '../utils';
import { useActivityListener } from './sessionExpiry';
import { useRefreshSessionQuery } from './graphql';

export const useSessionController = () => {
  const dispatch = useDispatch();
  const { isStopped } = useActivityMonitor();
  const { ttl, alertBefore: toastTime } = getSessionConfig();

  const onSessionExpire = useCallback(() => {
    dispatch(setExpiredByInactivity());
  }, [dispatch]);

  const [sessionStatus] = useReduxSubscription('SESSION_STATUS');
  const [refreshSession] = useRefreshSessionQuery({
    fetchPolicy: 'network-only',
  });

  // show toast after 4min
  const showToastAfter = ttl - toastTime;

  const {
    finished: finishedToastTimer,
    reinitialize: restartToastTimer,
  } = useTimer({ time: showToastAfter });

  const showToast = finishedToastTimer && isNil(sessionStatus);

  const onExtendSession = useCallback(() => {
    if (isStopped) return;
    refreshSession();
    restartToastTimer();
  }, [isStopped, refreshSession, restartToastTimer]);

  useEffect(() => {
    if (sessionStatus) return;
    restartToastTimer();
  }, [sessionStatus, restartToastTimer]);

  useActivityListener({ onExtendSession });

  return {
    sessionToastParams: {
      toastTime,
      showToast,
      onExtendSession,
      onSessionExpire,
    },
  };
};
