import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { WS_EVENT_NAMES } from 'config/constants';
import { updateSessionStatus } from 'actions/auth/session';
import { parseEventMessage } from 'utils/parseWSEventMessages';

export const useBindSessionInformation = ({
  bindEvent,
}) => {
  const dispatch = useDispatch();

  const callback = useCallback((message) => {
    const { data } = parseEventMessage(message);
    const { reason, client } = data || {};
    const payload = {
      reason,
      client,
    };
    const meta = { status: 'COMPLETED', error: null };
    dispatch(updateSessionStatus(payload, meta));
  }, [
    dispatch,
  ]);

  useEffect(() => bindEvent(
    WS_EVENT_NAMES.SESSION_STATUS,
    callback,
  ), [
    bindEvent,
    callback,
  ]);
};
