import {
  useEffect,
  useMemo,
} from 'react';
import { t as tBase } from 'i18n';
import { useForm, useWatch } from 'react-hook-form';
import { DEFAULT_PHONE_CODE, INVALID_OTP_ERROR } from 'config/constants';
import { filterOnlyNumbers } from 'utils/strings';
import { showSuccessAlert, showToastError } from 'utils/toastHandler';
import { resolver } from '../resolver';
import { useEditPhone } from './graphql';

const DEFAULT_VALUES = {
  otp: '',
  currentPhoneCode: DEFAULT_PHONE_CODE,
  currentPhoneNumber: '',
  newPhoneCode: DEFAULT_PHONE_CODE,
  newPhoneNumber: '',
};

const getIsMissingOTPOnly = (values, context) => {
  const { errors } = resolver(values, context);
  if (!errors) return false;
  return Object.entries(errors).length === 1 && errors?.otp;
};

const buildFormValues = (user) => {
  if (!user?.cellPhoneNumber) return null;
  const {
    cellPhoneCode = DEFAULT_PHONE_CODE,
  } = user;
  return {
    ...DEFAULT_VALUES,
    currentPhoneCode: cellPhoneCode,
    currentPhoneNumber: '',
  };
};

export const usePhoneForm = ({
  t,
  user,
  onSuccess,
  phoneCodes,
  shouldReset,
  cellPhoneCode,
  cellPhoneNumber,
}) => {
  const context = {
    cellPhoneCode,
    cellPhoneNumber,
    phoneCodeValidations: phoneCodes.validations,
  };
  const {
    reset,
    control,
    clearErrors,
    handleSubmit,
  } = useForm({
    context,
    resolver,
    defaultValues: DEFAULT_VALUES,
  });
  const currentValues = useWatch({ control });
  const handleSuccess = () => {
    showSuccessAlert({
      t,
      key: 'phoneUpdated',
    });
    onSuccess();
  };

  const [editPhone, { loading: isLoading }] = useEditPhone({
    onCompleted: handleSuccess,
    onErrorCode: {
      [INVALID_OTP_ERROR]: () => showToastError(tBase('common.errors.invalidOtp')),
    },
  });

  const isMissingOTPOnly = useMemo(
    () => getIsMissingOTPOnly(currentValues, context),
    [context, currentValues],
  );
  const handleEditPhone = (values) => {
    const {
      otp,
      newPhoneCode,
      newPhoneNumber,
    } = values;

    editPhone({
      variables: {
        input: {
          otpCode: otp,
          cellPhoneCode: filterOnlyNumbers(newPhoneCode),
          cellPhoneNumber: filterOnlyNumbers(newPhoneNumber),
        },
      },
    });
  };
  const onSubmit = handleSubmit(handleEditPhone);

  useEffect(() => {
    buildFormValues(user);
  }, [user]);

  useEffect(() => {
    if (shouldReset) reset(DEFAULT_VALUES);
  }, [shouldReset]);

  return {
    reset,
    control,
    onSubmit,
    isLoading,
    clearErrors,
    isMissingOTPOnly,
  };
};
