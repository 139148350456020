import { useReduxQuery } from 'utils/redux-query';
import { useAuthorizeWithOTP } from 'components/AuthorizeWithOTPField';
import { useEmailForm } from './useEmailForm';

export const useController = ({
  formProps,
}) => {
  const [session] = useReduxQuery('SESSION');
  const [secondFactor, { status }] = useReduxQuery('SECOND_FACTOR');
  const currentEmail = session?.user?.email ?? '';

  const {
    control,
    onSubmit,
    clearErrors,
    isSubmitting,
  } = useEmailForm({
    ...formProps,
    currentEmail,
  });

  const is2FAComplete = status.completed;
  const currentSecondFactor = secondFactor;
  const isRequestOTPDisabled = isSubmitting;

  const { otpFieldProps } = useAuthorizeWithOTP({
    is2FAComplete,
    currentSecondFactor,
    isRequestOTPDisabled,
    onResendOTP: () => clearErrors('otpCode'),
  });

  const otpProps = {
    ...otpFieldProps,
    isDisabled: isSubmitting,
    control,
  };

  const submitProps = {
    isDisabled: isSubmitting,
  };

  return {
    control,
    onSubmit,
    otpProps,
    submitProps,
  };
};
