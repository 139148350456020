import React from 'react';
import { t } from 'i18n';
import Footer from 'views/DashboardContent/FooterDashboard';
import {
  SelectField,
} from 'components/FormFields';
import {
  Grid,
  Flex,
  Button,
  Heading,
  Card,
  CardBody,
} from 'ui';
import { AuthorizeWithOTPField } from 'components/AuthorizeWithOTPField';
import { useController } from './hooks';
import { styles as s } from './AccountStatement.styles';
import { EmptyState } from './components';

const tView = (key, options) => t(key, { scope: 'settings.AccountStatements', ...options });
const tForm = (key, options) => t(key, { scope: 'settings.AccountStatements.downloadStatementForm', ...options });

function AccountStatements() {
  const {
    control,
    onSubmit,
    isLoading,
    displayForm,
    otpFieldProps,
    passwordFieldRef,
    isSubmitDisabled,
    displayEmptyState,
    isAvailableToRequest,
    optionsForSelectField,
    onGenerateAccountStatement,
  } = useController();

  return (
    <Flex {...s.container}>
      <Grid {...s.content}>
        <Heading {...s.title} data-testid="title">
          {tView('title')}
        </Heading>
        {displayEmptyState && (
          <EmptyState />
        )}
        {displayForm && (
          <>
            <Card
              as="form"
              onSubmit={onSubmit}
            >
              <CardBody {...s.cardBody}>
                <Grid {...s.cardContent}>
                  <SelectField
                    name="month"
                    control={control}
                    isDisabled={isLoading}
                    data-testid="month-field"
                    placeholder={tForm('fields.month.defaultOption')}
                    labelText={tForm('fields.month.labelText')}
                  >
                    {optionsForSelectField?.map(({
                      key,
                      label,
                      value,
                    }) => (
                      <option key={key} value={value}>
                        {label}
                      </option>
                    ))}
                  </SelectField>

                  <AuthorizeWithOTPField
                    name="otp"
                    autoFocus
                    control={control}
                    data-testid="otp"
                    isDisabled={isLoading}
                    ref={passwordFieldRef}
                    {...otpFieldProps}
                  />

                  <Flex {...s.actions}>
                    {isAvailableToRequest && (
                      <Button
                        type="button"
                        variant="link"
                        animationType="none"
                        data-testid="generate"
                        isDisabled={isLoading}
                        onClick={onGenerateAccountStatement}
                      >
                        {tForm('actions.generate.labelText')}
                      </Button>
                    )}
                    <Button
                      type="submit"
                      data-testid="submit"
                      isDisabled={isLoading || isSubmitDisabled}
                    >
                      {tForm('actions.submit.labelText')}
                    </Button>
                  </Flex>
                </Grid>
              </CardBody>
            </Card>
          </>
        )}
      </Grid>
      <Footer {...s.footer} />
    </Flex>
  );
}

export default AccountStatements;
