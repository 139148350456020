import React, { forwardRef } from 'react';
import {
  Text,
  Grid,
  Button,
  Center,
  Heading,
  ChatIcon,
} from 'ui';
import { OTPField } from 'components/FormFields';
import { maskPhoneNumber } from 'utils/maskHandler';
import SecondFactorModal from 'views/DashboardContent/Activate2FAWizard/SecondFactorModal';
import { styles as s } from './AuthorizeWithOTPField.style';
import { useController } from './hooks';

export const AuthorizeWithOTPField = forwardRef(({
  t: parentT,
  size,
  type,
  control,
  autoFocus,
  name = 'otp',
  'data-testid': testId,
  /**
   * props handled by useAuthorizeWithOTP hook
   */
  telephone,
  isDisabled,
  displayField,
  currentFactor,
  onSendOTPbySMS,
  isFieldDisabled,
  remainingSeconds,
  displaySMSContent,
  isCounterDisabled,
  displayCounterButton,
  displayFactorDisabled,
  displayWaitingCounter,
  /**
   * props handled by useAuthorizeWithOTP hook
   */
  ...props
}, ref) => {
  const {
    t,
    error,
    enable2FADisclosure,
  } = useController({
    name,
    control,
    parentT,
  });

  return (
    <Grid {...s.container} data-testid={`${testId}.container`} {...props}>
      {displayField && (
        <OTPField
          ref={ref}
          name={name}
          size={size}
          control={control}
          type={type}
          isDisabled={isDisabled || isFieldDisabled}
          data-testid={`${testId}.field`}
          alignSelf="center"
          autoFocus={autoFocus}
          labelText={t(
            'label',
            {
              current: t(
                `secondFactorTypes.${currentFactor}`,
                { defaultValue: t('secondFactorTypes.loading') },
              ),
            },
          )}
          labelProps={{
            overflow: 'visible',
            noOfLines: 2,
          }}
        />
      )}
      {displayCounterButton && (
        <Button
          {...s.counterButton}
          size={size}
          onClick={onSendOTPbySMS}
          isDisabled={isDisabled || isCounterDisabled}
          data-testid={`${testId}.counter`}
        >
          {displayWaitingCounter
            ? t('waitingToResend', { count: remainingSeconds })
            : t('resendText')}
        </Button>
      )}
      {displaySMSContent && (
        <Center
          {...s.SMSContent}
          data-testid={`${testId}.smsDisclaimer`}
        >
          <Text>
            {t('smsDisclaimer', { tel: maskPhoneNumber(telephone) })}
          </Text>
          <Button
            onClick={onSendOTPbySMS}
            rightIcon={<ChatIcon />}
            data-testid={`${testId}.resendSMS`}
            isDisabled={isDisabled || isCounterDisabled}
          >
            {displayWaitingCounter
              ? t('sendBySMSCounter', { count: remainingSeconds })
              : t('sendBySMS')}
          </Button>
          {error && <Text fontSize="sm" color="red.500">{error?.message}</Text>}
        </Center>
      )}
      {displayFactorDisabled && (
        <Center {...s.disabledFactorContent}>
          <Heading size="sm">
            {t('factorDisabled.title')}
          </Heading>
          <Text>{t('factorDisabled.desc')}</Text>
          <Button
            {...s.disabledFactorButton}
            onClick={enable2FADisclosure.onOpen}
          >
            {t('factorDisabled.enable')}
          </Button>
          <SecondFactorModal
            {...enable2FADisclosure}
            onRequestClose={enable2FADisclosure.onClose}
          />
        </Center>
      )}
    </Grid>
  );
});
