import {
  useRef,
  useState,
  useCallback,
} from 'react';
import { useClient } from './useClient';

export const useChannel = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const { client, connectionState } = useClient();
  const channel = useRef(null);

  const subscribe = useCallback(({ channelName }) => {
    if (connectionState?.current !== 'connected') return () => {};
    channel.current = client.subscribe(channelName);
    channel.current.bind('pusher:subscription_succeeded', () => {
      setIsLoading(false);
      setIsSubscribed(true);
    });

    channel.current.bind('pusher:subscription_error', () => {
      setIsSubscribed(true);
    });

    return () => {
      setIsSubscribed(false);
      client.unsubscribe(channelName);
      channel.current = null;
    };
  }, [connectionState?.current]);

  return {
    isLoading,
    subscribe,
    isSubscribed,
    channel: channel.current,
  };
};
