import React from 'react';
import { l, tscope } from 'i18n';
import {
  Text,
  Grid,
  Flex,
  Card,
  Modal,
  Button,
  Heading,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from 'ui';
import { AutoInvestIcon, PauseIcon } from 'components/Icons';
import { PauseAutoinvestForm } from '../components';
import { useController } from './hooks';
import { DisplayAutoinvestConfig } from '../components/DisplayAutoinvestConfig/DisplayAutoinvestConfig';
import { TermsAndConditions } from '../TermsAndConditions';

const ts = tscope('settings.autoinvest');

const buildActiveStatus = ({ status }) => {
  if (status === 'active') {
    return {
      container: {
        borderColor: 'green.300',
      },
      Icon: AutoInvestIcon,
      icon: {
        color: 'green.500',
      },
      description: {
        color: 'green.500',
      },
    };
  }
  if (status === 'paused') {
    return {
      container: {
        borderColor: 'gray.300',
      },
      Icon: PauseIcon,
      icon: {
        color: 'gray.500',
      },
      description: {
        color: 'gray.500',
      },
    };
  }
  return {};
};

const AutoinvestStatusAlert = ({ status = 'paused', currentStatusUpdatedAt }) => {
  const statusProps = buildActiveStatus({ status });

  return (
    <Flex
      gap="5"
      padding="5"
      borderRadius="lg"
      alignItems="center"
      border="1px solid"
      {...statusProps.container}
    >
      <statusProps.Icon boxSize="6" {...statusProps.icon} />
      <Text fontSize="lg" {...statusProps.description}>
        {ts(`statusAlert.${status}`, { date: l('date.formats.long', currentStatusUpdatedAt) })}
      </Text>
    </Flex>
  );
};

export const ShowAutoinvest = () => {
  const {
    isLoading,
    isAutoinvestOff,
    onEditAutoinvest,
    currentAutoinvestConfig,
    displayPauseAutoinvestForm,
    onDisplayPauseAutoinvestForm,
  } = useController();

  if (isAutoinvestOff) {
    return (
      <TermsAndConditions />
    );
  }

  const status = currentAutoinvestConfig?.active ? 'active' : 'paused';
  const currentStatusUpdatedAt = status === 'active' ? currentAutoinvestConfig?.updatedAt : currentAutoinvestConfig?.updatedAt;

  return (
    <Grid
      gap="5"
      padding="5"
      marginX="auto"
      width={{ base: 'full', xl: '4xl' }}
    >
      <AutoinvestStatusAlert
        status={status}
        currentStatusUpdatedAt={currentStatusUpdatedAt}
      />
      <Flex justifyContent="flex-end" gap="5" marginY="2">
        <Button
          variant="outline"
          isDisabled={isLoading}
          onClick={onDisplayPauseAutoinvestForm}
        >
          {currentAutoinvestConfig?.active
            ? 'Pausar'
            : 'Activar'}
        </Button>
        <Button
          isDisabled={isLoading}
          onClick={onEditAutoinvest}
        >
          Editar
        </Button>
      </Flex>
      <Heading
        as="h4"
        fontSize="lg"
        opacity={currentAutoinvestConfig?.active ? '1' : '0.4'}
      >
        Criterios de préstamo
      </Heading>
      <Card
        padding="10"
        opacity={currentAutoinvestConfig?.active
          ? '1'
          : '0.4'}
      >
        <DisplayAutoinvestConfig />
      </Card>

      <Modal
        isOpen={displayPauseAutoinvestForm}
        onClose={onDisplayPauseAutoinvestForm}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <PauseAutoinvestForm
            onCancel={onDisplayPauseAutoinvestForm}
            onSuccess={onDisplayPauseAutoinvestForm}
          />
        </ModalContent>
      </Modal>
    </Grid>
  );
};
