import { useCallback } from 'react';
import { useReduxQuery } from 'utils/redux-query';
import { usePhoneCodes } from 'hooks/usePhoneCodes';
import { useAuthorizeWithOTP } from 'components/AuthorizeWithOTPField';
import { usePhoneQuery } from './graphql';
import { usePhoneForm } from './usePhoneForm';

export const useController = ({
  t,
  onSuccess,
  shouldReset,
}) => {
  const { data, loading } = usePhoneQuery();
  const [secondFactor, { status }] = useReduxQuery('SECOND_FACTOR');
  const { user } = data || {};
  const phoneCodes = usePhoneCodes();
  const handleSuccess = () => {
    onSuccess();
  };
  const {
    control,
    onSubmit,
    clearErrors,
    isMissingOTPOnly,
    isLoading: isFormLoading,
  } = usePhoneForm({
    t,
    user,
    phoneCodes,
    shouldReset,
    onSuccess: handleSuccess,
    cellPhoneNumber: user?.cellPhoneNumber,
    cellPhoneCode: user?.cellPhoneCode,
  });
  const {
    otpFieldProps,
  } = useAuthorizeWithOTP({
    telephone: user?.cellPhoneNumber,
    is2FAComplete: status.completed,
    currentSecondFactor: secondFactor,
    isRequestOTPDisabled: !isMissingOTPOnly || isFormLoading,
    onResendOTP: useCallback(() => {
      clearErrors('otp');
    }, [clearErrors]),
  });
  const isLoading = loading || isFormLoading || status.pending;

  const newPhoneProps = {
    control,
    isDisabled: isLoading,
  };

  const currentPhoneProps = {
    control,
    isDisabled: isLoading,
  };

  const otpProps = {
    ...otpFieldProps,
    control,
    isDisabled: isLoading,
  };

  const submitProps = {
    isDisabled: isLoading,
  };

  return {
    control,
    onSubmit,
    isLoading,
    phoneCodes,
    otpFieldProps,
    otpProps,
    submitProps,
    newPhoneProps,
    currentPhoneProps,
  };
};
