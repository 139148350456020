import { ApolloLink } from '@apollo/client';
import store from 'store';
import {
  hasPopulatedCredentials,
} from 'utils/session';

const buildContext = (context) => {
  const headers = context?.headers || {};
  const credentials = store.getState().auth.credentials || {};
  const userId = store.getState().SESSION.payload.user.id;
  const authHeaders = {
    uid: credentials.uid,
    expiry: credentials.expiry,
    client: credentials.client,
    'token-type': credentials['token-type'],
    'access-token': credentials['access-token'],
    'X-User-Id': userId,
  };

  if (context.password) authHeaders.password = encodeURIComponent(context.password);
  if (context.otpCode) authHeaders['X-OTP-Code'] = context.otpCode;

  return {
    headers: {
      ...headers,
      ...authHeaders,
    },
  };
};

const getCredentialsFromHeaders = (headers) => ({
  uid: headers.get('uid'),
  expiry: headers.get('expiry'),
  client: headers.get('client'),
  'token-type': headers.get('token-type'),
  'access-token': headers.get('access-token'),
});

export const headersLink = new ApolloLink((operation, forward) => {
  operation.setContext(buildContext);

  const isPublic = operation.getContext().clientName === 'public';

  return forward(operation).map((data) => {
    const headers = operation.getContext()?.response?.headers || null;
    const credentials = getCredentialsFromHeaders(headers);

    if (!isPublic && hasPopulatedCredentials(credentials)) {
      // TODO: uncomment this line when we remove redux-query from the project.
      // Redux persist the credential under de hook.
      // storeSession(credentials);
      store.dispatch({ type: 'SET_CREDENTIALS', payload: credentials });
    }
    return data;
  });
});
