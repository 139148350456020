import {
  useEffect,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { useReduxQuery, useReduxSubscription } from 'utils/redux-query';
import { useChannel } from 'hooks/webSocket';
import { USER_STATUSES } from 'config/constants';

export const usePrivateInvestorChannel = () => {
  const {
    uid,
    'access-token': accessToken,
  } = useSelector(({ auth }) => auth.credentials);
  const [session] = useReduxQuery('SESSION');
  const [sessionStatus] = useReduxSubscription('SESSION_STATUS');
  const user = session?.user;
  const channelName = user ? `private-${user?.id}` : null;
  const isUserBlocked = session?.user?.status === USER_STATUSES.BLOCKED;
  const {
    channel,
    subscribe,
    isLoading,
    isSubscribed,
  } = useChannel();

  const bindEvent = useCallback((eventName, callback) => {
    if (!isSubscribed) return () => {};
    channel.bind(eventName, callback);
    return () => {
      channel.unbind(eventName, callback);
    };
  }, [isSubscribed]);

  useEffect(() => {
    const shouldUnsubscribe = [
      !uid,
      !accessToken,
      !channelName,
      isUserBlocked,
      sessionStatus,
    ].some(Boolean);
    if (shouldUnsubscribe) return () => {};
    return subscribe({ channelName });
  }, [
    uid,
    subscribe,
    accessToken,
    channelName,
    channelName,
    isUserBlocked,
    sessionStatus,
  ]);

  return {
    isLoading,
    bindEvent,
    isSubscribed,
    privateInvestorChannel: channel,
  };
};

export const useEvent = (event, callback) => {
  const {
    bindEvent,
  } = usePrivateInvestorChannel();

  useEffect(() => bindEvent(event, callback), [
    event,
    callback,
    bindEvent,
  ]);
};
