import {
  useRef,
  useMemo,
  useEffect,
} from 'react';
import { l } from 'i18n';
import useQuery from 'hooks/useQuery';
import { useAuthorizeWithOTP } from 'components/AuthorizeWithOTPField';
import { useReduxQuery } from 'utils/redux-query';
import {
  useUser,
} from './graphql';
import { useRequestAccountStatementForm } from './useRequestAccountStatementForm';
import { useRequestLastAccountStatement } from './useRequestLastAccountStatement';

const createOptionsForSelectField = (statementAccounts = []) => statementAccounts
  .map(({ id, periodStart }) => ({
    key: id,
    value: id,
    label: l('date.formats.short', periodStart),
  }));

const useAutoSelectMonth = ({
  setValue,
  lastAccountStatement,
}) => {
  const inputRef = useRef(null);
  const query = useQuery();
  const monthParam = query.get('month');

  useEffect(() => {
    if (!monthParam || !lastAccountStatement) return;
    setValue('month', lastAccountStatement);
  }, [monthParam, lastAccountStatement]);
  return {
    inputRef,
  };
};

export const useController = () => {
  const { data, loading: isLoadingUser } = useUser();
  const { accountStatements, flags } = data?.user || {};
  const { isAccountStatementAvailable } = flags || {};
  const [
    secondFactor,
    { isCompleted: is2FACompleted },
  ] = useReduxQuery('SECOND_FACTOR');
  const optionsForSelectField = useMemo(
    () => createOptionsForSelectField(accountStatements),
    [accountStatements],
  );
  const lastAccountStatement = optionsForSelectField?.[0]?.value;
  const {
    month,
    control,
    onSubmit,
    setValue,
    isLoading: isFormLoading,
  } = useRequestAccountStatementForm();
  const {
    inputRef,
  } = useAutoSelectMonth({
    setValue,
    lastAccountStatement,
  });
  const {
    isAvailableToRequest,
    requestLastAccountStatement,
    isLoading: isGenerateLoading,
  } = useRequestLastAccountStatement({
    isAccountStatementAvailable,
  });

  const onGenerateAccountStatement = () => {
    requestLastAccountStatement();
  };

  const { otpFieldProps, isOTPFieldVisible } = useAuthorizeWithOTP({
    isRequestOTPDisabled: !month,
    is2FAComplete: is2FACompleted,
    currentSecondFactor: secondFactor,
    telephone: data?.user?.cellPhoneNumber,
  });

  const isLoading = isLoadingUser || isGenerateLoading || isFormLoading;

  const isSubmitDisabled = [
    is2FACompleted && !secondFactor,
    !isOTPFieldVisible,
  ].some(Boolean);

  const displayEmptyState = accountStatements?.length === 0;

  const displayForm = accountStatements?.length > 0;

  return {
    control,
    onSubmit,
    isLoading,
    displayForm,
    otpFieldProps,
    isSubmitDisabled,
    displayEmptyState,
    isAvailableToRequest,
    optionsForSelectField,
    onGenerateAccountStatement,
    passwordFieldRef: inputRef,
  };
};
