import React from 'react';
import {
  Flex,
  Text,
  Center,
  Divider,
  Collapse,
  EditIcon,
  IconButton,
} from 'ui';

export const EditableItem = ({
  label,
  value,
  onEdit,
  children,
  isEditable,
  explanation,
  needsDivider,
}) => (
  <>
    <Flex
      paddingX="2rem"
      columnGap="1rem"
      minHeight="5rem"
      alignItems="center"
      justifyContent="space-between"
    >
      <Text fontSize="md" minWidth={20}>
        {label}
      </Text>
      <Text
        as="strong"
        flexGrow={1}
        fontSize="md"
      >
        {value}
      </Text>
      <IconButton
        variant="ghost"
        onClick={onEdit}
        icon={<EditIcon />}
      />
    </Flex>
    {explanation && (
      <Text padding="0 2rem 1rem">
        {explanation}
      </Text>
    )}
    <Collapse
      in={isEditable}
      borderRadius="md"
      backgroundColor="gray.100"
    >
      <Center
        borderRadius="md"
        backgroundColor="gray.100"
        marginBottom={needsDivider ? '4' : '0'}
      >
        {children}
      </Center>
    </Collapse>
    {needsDivider && <Divider />}
  </>
);
