import React from 'react';
import { t } from 'i18n';
import { forgotPasswordUrl } from 'config';
import {
  Box,
  Text,
  Link,
  Modal,
  Input,
  HStack,
  Button,
  Heading,
  ModalBody,
  FormLabel,
  InputGroup,
  FormControl,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  FormHelperText,
  InputRightElement,
} from 'ui';
import { useController } from './hooks/useController';
import { styles } from './ExpiredSessionModal.style';

const tl = (key, options) => t(key, { scope: 'components.ExpiredSessionModal', ...options });

export const ExpiredSessionModal = () => {
  const {
    isOpen,
    isError,
    content,
    isLoading,
    onEndSession,
    onStartSession,
    onChangePasswordInput,
  } = useController();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onEndSession}
      isCentered
      closeOnOverlayClick={false}
      {...styles.modal}
    >
      <ModalOverlay />
      <ModalContent {...styles.modalContent}>
        <ModalHeader>
          <Heading {...styles.header}>
            {tl('title')}
          </Heading>
        </ModalHeader>
        <ModalBody>
          <Box {...styles.container} as="form" onSubmit={onStartSession}>
            <FormControl isDisabled={isLoading} isRequired isInvalid={isError}>
              <FormLabel>{tl('fields.password.label')}</FormLabel>
              <InputGroup>
                <Input
                  autoFocus
                  type="password"
                  placeholder={tl('fields.password.placeholder')}
                  data-testid="password-input"
                  onChange={onChangePasswordInput}
                  isDisabled={isLoading}
                  {...styles.input}
                />
                <InputRightElement
                  {...styles.inputRightElement}
                >
                  <Button
                    {...styles.submit}
                    isDisabled={isLoading}
                    type="submit"
                  >
                    {tl('actions.continue')}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {isError && (
                <FormHelperText {...styles.textHelper}>
                  {tl('fields.password.error.invalid')}
                </FormHelperText>
              )}
            </FormControl>
            <HStack {...styles.hStack}>
              <Link
                {...styles.links}
                href={forgotPasswordUrl()}
              >
                {tl('actions.forgotPassword')}
              </Link>
              <Button
                {...styles.links}
                isDisabled={isLoading}
                onClick={onEndSession}
              >
                {tl('actions.closeSession')}
              </Button>
            </HStack>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Text data-testid="ExpiredSessionModal.footerText" {...styles.text}>{content}</Text>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
