import React, { useState } from 'react';
import { t } from 'i18n';
import { EditableItem, Panel } from 'components';
import { maskPhoneNumber } from 'utils/maskHandler';
import {
  PhoneForm,
} from './components';
import { usePhoneQuery } from './components/PhoneNumberForm/hooks/graphql';

const ts = (id) => t(id, { scope: 'Dashboard.Settings.Account.ContactsPanel' });

const ContactsPanel = () => {
  const { data } = usePhoneQuery();
  const { user } = data || {};

  const [showPhoneForm, setShowPhoneForm] = useState(false);

  return (
    <Panel
      title={ts('titleData')}
      maxWidth="100%"
      mb="2rem"
      innerContentProps={{
        px: '1.4rem',
        pt: '1.4rem',
        pb: '1.4rem',
      }}
    >
      <EditableItem
        label={ts('items.1')}
        onEdit={() => {
          setShowPhoneForm(!showPhoneForm);
        }}
        value={maskPhoneNumber(user?.cellPhoneNumber)}
        isEditable={showPhoneForm}
      >
        <PhoneForm
          shouldReset={!showPhoneForm}
          onCancel={() => {
            setShowPhoneForm(false);
          }}
          onSuccess={() => {
            setShowPhoneForm(false);
          }}
        />
      </EditableItem>
    </Panel>
  );
};

export default ContactsPanel;
